import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import WelcomeSection from "../components/welcomeSection"
import AboutSection from "../components/aboutSection"
import ContactSection from "../components/contactSection"
import { graphql, useStaticQuery } from "gatsby"
import ProjectsSection from "../components/projectsSection"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteIndexQuery {
      site {
        siteMetadata {
          missionStatement,
          aboutParagraph,
          servicesOffered,
          contactTagLine,
          email,
          phone,
          address {
            street,
            city,
            postalCode
          },
          mapUrl
        }
      }
    }
  `);

  const siteMetadata = data.site.siteMetadata;

  return (
    <Layout>
      <SEO title="Home" />

      <WelcomeSection
        missionStatement={siteMetadata.missionStatement} />

      <AboutSection
        aboutParagraph={siteMetadata.aboutParagraph}
        servicesOffered={siteMetadata.servicesOffered} />

      <ProjectsSection />

      <ContactSection
        contactTagLine={siteMetadata.contactTagLine}
        email={siteMetadata.email}
        phone={siteMetadata.phone}
        address={siteMetadata.address}
        mapUrl={siteMetadata.mapUrl} />
    </Layout>
  )
}

export default IndexPage
