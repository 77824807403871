import React from "react"
import Project from "./project"
import './projectsSection.css';

const ProjectsSection = () => {
  const projects = [{
    title: 'Scion FR-S Headers',
    description: 'These performance exhaust headers were designed and fabricated by Rallyworks for the Scion FR-S / Subaru BRZ / Toyota 86 platform. They feature:',
    features: ['True equal length', '304 Stainless Steel', 'Fully back-purged and TIG welded']
  }]

  return (
    <section id="projects">
      <h1>Projects</h1>

      {projects.map((project) => (
        <Project
          key={project.title}
          title={project.title}
          description={project.description}
          features={project.features} />
      ))}

    </section>
  )
}

export default ProjectsSection
