import React from "react"
import './aboutSection.css';
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const AboutSection = ({ aboutParagraph, servicesOffered }) => {
  const data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "mosport-winter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="about" className="about-section">
      <div className="image-container">
        <div style={{ maxWidth: `600px` }}>
          <Img fluid={data.aboutImage.childImageSharp.fluid}
               objectFit="cover" />
        </div>
      </div>
      <div className="text-section-container">
        <h1>About</h1>
        <p>{aboutParagraph}</p>

        <p>Services we offer include:</p>
        <ul>
          {servicesOffered && servicesOffered.map((service, index) => (<li key={index}>{service}</li>))}
        </ul>
      </div>
    </section>
  )
}

AboutSection.propTypes = {
  aboutParagraph: PropTypes.string,
  servicesOffered: PropTypes.array
}

export default AboutSection
