import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//TODO: Figure out what happened to my Maple Leaf icon
import { faEnvelope, faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons"

import './contactSection.css';
import PropTypes from "prop-types"
import Phone from "./phone"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ContactSection = ({ contactTagLine, email, phone, address, mapUrl }) => {
  const data = useStaticQuery(graphql`
    query {
      mapImage: file(relativePath: { eq: "map.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="contact" className="contact-section">
      <div className="text-section-container">
        <h1>Contact</h1>
        <p>{contactTagLine}</p>

        <div className="contact-block">
          <a href={mapUrl} target="_blank" rel="noopener noreferrer">
            <div className="icon-container">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </div>
            <div className="text-container">
              <h2>Address</h2>
              <address>
                <p>{address.street}</p>
                <p>{address.city}</p>
                <p>{address.postalCode}</p>
              </address>
            </div>
          </a>
        </div>

        <div className="contact-block">
          <a href={`tel:${phone}`} rel="nofollow">
            <div className="icon-container">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="text-container">
              <h2>Phone</h2>
              <Phone phone={phone} />
            </div>
          </a>
        </div>

        <div className="contact-block">
          <a href={`mailto:${email}`} rel="nofollow">
            <div className="icon-container">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="text-container">
              <h2>Email</h2>
              <span itemProp="email">{email}</span>
            </div>
          </a>
        </div>
      </div>

      <div className="map-container">
        <a href={mapUrl} target="_blank" rel="noopener noreferrer" className="map-link">
          <Img fluid={data.mapImage.childImageSharp.fluid}
               alt={`Map of ${address.street} ${address.city}`}
               title={`${address.street} ${address.city}`} />
        </a>
      </div>
    </section>
  )
}

ContactSection.propTypes = {
  contactTagLine: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.object,
  mapUrl: PropTypes.string
}

export default ContactSection
