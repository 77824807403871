import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { Link } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import './project.css'

const Project = ({ title, description, features }) => {
  //TODO: This image query needs to come from the params:
  const data = useStaticQuery(graphql`
    query {
      headersImage: file(relativePath: { eq: "frs-headers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="project-container">
      <h2>{title}</h2>
      <Img fluid={data.headersImage.childImageSharp.fluid} objectFit="cover" />
      <p>{description}</p>
      <ul>
        {features.map((feature, index) => (<li key={index}>{feature}</li>))}
      </ul>
      <p>Please <Link to={`/#contact`}>contact us</Link> for a price quote.</p>
    </div>
  )
}

Project.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  features: PropTypes.array
}

export default Project
