import React from "react"
import './welcomeSection.css';

import LogoFull from "../images/logo-full.svg"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const WelcomeSection = ({ missionStatement }) => {
  const data = useStaticQuery(graphql`
    query {
      welcomeImage: file(relativePath: { eq: "welcome-background-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="welcome" className="welcome-section">
      <div className="welcome-background-container">
        <Img fluid={data.welcomeImage.childImageSharp.fluid} alt="Welcome background" />
      </div>
      <div className="welcome-block" id="welcomeBlock">
        <LogoFull className="welcome-logo" />
        <p>{missionStatement}</p>
      </div>
    </section>
  )
}

export default WelcomeSection
